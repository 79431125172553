'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

export const MobileLoginLink = () => {
  const pathName = usePathname();

  return (
    <Link href={`/login?callback=${pathName}`}>
      <Avatar className='h-8 w-8'>
        <AvatarImage src={'/icons/user.svg'} alt={'user'} />
        <AvatarFallback>A</AvatarFallback>
      </Avatar>
    </Link>
  );
};
