'use client';

import { useScopedI18n } from '@/lib/i18n/client';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

interface Props {
  className?: string;
}

export const LoginLink = ({ className }: Props) => {
  const t = useScopedI18n('navbar');

  const pathName = usePathname();

  const params = useSearchParams();
  const prv = params.get('prv');

  const loginLink = `/login?callback=${pathName}${prv ? `?prv=${prv}` : ''}`;

  return (
    <Link href={loginLink} className={className}>
      <Image src='/icons/user.svg' alt='user' width={20} height={20} />
      {t('login')}
    </Link>
  );
};
