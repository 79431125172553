'use client';

import { useScopedI18n } from '@/lib/i18n/client';
import { CountryCode } from '@/types/country-codes';
import { GlobeIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { Button } from '../ui/button';
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from '../ui/sheet';
import { countries } from './country-selector';
import { LocaleSelector } from './locale-selector';

interface Props {
  countryEnv: CountryCode;
}

export const MobileCountrySelector = ({ countryEnv }: Props) => {
  const t = useScopedI18n('navbar');

  const searchParams = useSearchParams();

  const countryCode = searchParams.get('country');

  const currentCountry =
    countryEnv === 'US'
      ? countries[0]
      : countries.find((country) => country.code === countryCode) ||
        countries[1];

  const filteredCountries = countries.filter(
    (country) => country.code !== currentCountry.code,
  );

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Image
          src={currentCountry.flag}
          alt={currentCountry.code}
          width={30}
          height={30}
        />
      </SheetTrigger>
      <SheetContent side='right' className='border-[#27272a]/60'>
        <SheetTitle>{t('language')}</SheetTitle>

        <div className='mt-6 flex justify-center'>
          <LocaleSelector />
        </div>

        <SheetTitle className='mt-10'>{t('country')}</SheetTitle>

        <ul className='mt-6 space-y-4'>
          {filteredCountries.map((c) => (
            <li key={c.code}>
              <Button
                variant='ghost'
                className='flex items-center justify-start space-x-2'
                asChild
              >
                <Link
                  href={
                    countryEnv === 'US'
                      ? 'https://www.smartticket.fun/index.aspx'
                      : c.code === 'US'
                        ? 'https://www.smartticketusa.com/es'
                        : `/?country=${c.code}`
                  }
                >
                  <Image src={c.flag} alt={c.code} width={30} height={30} />
                  <span>
                    {t(c.code)}{' '}
                    {c.code === currentCountry.code ? t('selected') : ''}
                  </span>
                </Link>
              </Button>
            </li>
          ))}
        </ul>
      </SheetContent>
    </Sheet>
  );
};
