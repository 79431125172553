'use client';

import { useScopedI18n } from '@/lib/i18n/client';
import { CountryCode } from '@/types/country-codes';
import { ChevronDown } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

export type NavCountry = {
  code: CountryCode;
  flag: string;
};

export const countries: NavCountry[] = [
  {
    code: 'US',
    flag: '/countries-icons/usa.svg',
  },
  {
    code: 'SV',
    flag: '/countries-icons/esa.svg',
  },
  {
    code: 'GT',
    flag: '/countries-icons/gt.svg',
  },
  {
    code: 'HN',
    flag: '/countries-icons/ht.svg',
  },
];

interface Props {
  countryEnv: CountryCode;
}

export const CountrySelector = ({ countryEnv }: Props) => {
  const t = useScopedI18n('navbar');

  const searchParams = useSearchParams();

  const countryCode = searchParams.get('country');

  const currentCountry =
    countryEnv === 'US'
      ? countries[0]
      : countries.find((country) => country.code === countryCode) ||
        countries[1];

  const filteredCountries = countries.filter(
    (country) => country.code !== currentCountry.code,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className='flex items-center gap-x-2'>
          <Image
            src={currentCountry.flag}
            alt={currentCountry.code}
            width={30}
            height={30}
          />

          <span className='lg:hidden'>
            {t(currentCountry.code)}
            {currentCountry.code === 'US' ? '(Selected)' : ''}
          </span>

          <ChevronDown size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='rounded-lg border-[#27272a]/60 bg-black p-0'>
        {filteredCountries.map((c) => (
          <DropdownMenuItem key={c.code} asChild>
            <Button
              variant='ghost'
              className='flex w-full cursor-pointer items-center justify-start py-6 text-white'
              asChild
            >
              <Link
                href={
                  countryEnv === 'US'
                    ? 'https://www.smartticket.fun/index.aspx'
                    : c.code === 'US'
                      ? 'https://www.smartticketusa.com/es'
                      : `/?country=${c.code}`
                }
              >
                <Image src={c.flag} alt={c.code} width={30} height={30} />
                <span className='pl-2'>
                  {t(c.code)}
                  {c.code === currentCountry.code ? '(Selected)' : ''}
                </span>
              </Link>
            </Button>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
