'use client';

import { cn } from '@/lib/cn';
import { useParams, usePathname, useRouter } from 'next/navigation';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface Props {
  className?: string;
}

export const LocaleSelector = ({ className }: Props) => {
  const { lang } = useParams<{ lang: string }>();

  const router = useRouter();
  const pathname = usePathname();

  const handleLocaleChange = (selectedLang: string) => {
    const newPathname = pathname.replace(`/${lang}`, `/${selectedLang}`);

    router.push(newPathname);
  };

  return (
    <Tabs
      className={cn(
        'rounded-lg bg-background p-1 text-base font-light',
        className,
      )}
      value={lang}
    >
      <TabsList className='max-lg:bg-[#27272a] max-lg:py-4'>
        <TabsTrigger
          className='max-lg:px-8 max-lg:py-2'
          value='en'
          onClick={() => {
            handleLocaleChange('en');
          }}
        >
          EN
        </TabsTrigger>
        <TabsTrigger
          className='max-lg:px-8 max-lg:py-2'
          value='es'
          onClick={() => {
            handleLocaleChange('es');
          }}
        >
          ES
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );

  return (
    <button
      className={cn(
        'rounded-lg bg-background p-1 text-base font-light',
        className,
      )}
      onClick={() => {
        const selectedLang = lang === 'en' ? 'es' : 'en';
        const newPathname = pathname.replace(`/${lang}`, `/${selectedLang}`);

        router.push(newPathname);
      }}
    >
      {lang === 'en' ? 'ES' : 'EN'}
    </button>
  );

  return (
    <>
      <label htmlFor='locale' className='sr-only'>
        Select your language:
      </label>

      <select
        className={cn(
          'rounded-lg bg-background p-1 text-base font-light',
          className,
        )}
        id='locale'
        defaultValue={lang}
        onChange={(e) => {
          const selectedLang = e.target.value;
          const newPathname = pathname.replace(`/${lang}`, `/${selectedLang}`);

          router.push(newPathname);
        }}
      >
        <option value='en'>EN</option>
        <option value='es'>ES</option>
      </select>
    </>
  );
};
